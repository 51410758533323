.joinWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;   
    background-color: var(--crt-bg-100-hl); 
}

.join {
    text-align: center;
}

.interactiveBox {
    width: 400px;
    height: 374px;
    border: 2px solid var(--crt-fg-100);
    background-color: var(--crt-bg-100);
    overflow-y: scroll;
    overflow-x: hidden;
}

.interactiveBox > div, .interactiveBox > input {
    margin-left: -2px;
}

.interactiveBox > div:first-child {
    margin-top: -2px;
}
.content {
    text-align: justify;
    line-height: 1.2;
    font-size: 14px;
    color: var(--crt-fg-100);
    overflow: hidden;
    padding-top: 80px;
    height: 0;
    padding: 0 20px;
}
.content.unfold {
    padding: 20px;
    padding-top: 80px;
    height: max-content;
}
.warning {
    margin: 15px;
    font-size: 14px;
    height: 15px;
    font-weight: 500;
    color: var(--crt-warning);
}
.btnWrapper {
    transition: all 1s;
}
.fix {
    position: fixed;
}
.fix:first-child {
    background-color: black;
}
.profile > .filebox {
    height: 248px;
    border-bottom: 2px solid var(--crt-fg-100);
    display: flex;
    justify-content: center;
    align-items: center;
}
.none {
    display: none;
}
.filebox > .button {
    height: 192px;
    width: 192px;
    border-radius: 96px;
    border: 2px solid var(--crt-fg-100);
    cursor: var(--cursor-pointer);;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filebox > input[type="file"]{
    display: none;
}
.cropperWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.cropper {
    position: relative;
    width: 800px;
    height: 400px;
    background: #333;
}
.croppedImage {
    width: 192px;
    height: 192px;
    border-radius: 96px;
    border: 2px solid var(--crt-fg-100);
}