.input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border: 2px solid var(--crt-fg-100);
    background-color: var(--crt-bg-100);
    font-size: 16px;
    font-weight: 600;
    color: var(--crt-fg-100);
    text-align: left;
    position: relative;
}

.input > div {
    display: flex;
    padding-left: 15px;
    align-items: center;
    width: 100%;
    height: 100%;
}

.input > input {
    border: 0;
    width: 100%;
    height: 58px;
    margin-top: 0;
}

.input > button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    height: 30px;
    width: 64px;
    border: 2px solid var(--crt-fg-100);
    color: var(--crt-fg-100);
    background-color: var(--crt-bg-100);
    border-radius: 16px;
    font-size: 12px;
    font-weight: 800;
    transition: all .3s;
    cursor: var(--cursor-pointer);
}

.top {
    border-top: 2px solid var(--crt-fg-100);
}

button.highlighted {
    color: var(--crt-bg-100);
    background-color: var(--crt-fg-100);
}
button.finished {
    color: var(--crt-bg-100);
    background-color: var(--crt-fg-300);
    border-color: var(--crt-fg-300);
}
button.hidden {
    visibility: hidden;
}
button.loading {
    width: 30px;
    background-color: var(--crt-fg-100);
    border: 0px;
}

.iconContainer {
    width: 18px;
    height: 18px;
}

.iconContainer > svg {
    width: 18px;
    height: 18px;
    animation: infinite-rotate 3s linear infinite;
    transform-origin: 50% 50%;
}

@keyframes infinite-rotate {
  100% {
    transform: rotate(360deg);
  }
}