
.searchBoxContainer {
  width: 100%;
  position: relative;
  border-bottom: 2px solid var(--crt-fg-100);
}

.searchBox {
  width: calc(100% - 89px);
  height: 46px;
  border-radius: 40px;
  background-color: var(--crt-bg-200);
  font-size: 16px;
  padding-left: 25px;
  padding-right: 40px;
  margin: 10px 15px;
  font-weight: 600;
  border: 0;
}

.searchButton {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: var(--cursor-pointer);
  width: 25px;
  height: 25px;
}

.searchButton > svg {
  width: 25px;
  height: 25px;
}

.searchResult {
  height: calc(100% - 117px);
  overflow: scroll;
}

.gridView {
  display: flex;
  flex-wrap: wrap;
}

.gridItem {
  width: calc(50% - 1px);
  border-top: 2px solid var(--crt-bg-100);
  position: relative;
}

.gridItem:nth-of-type(1), .gridItem:nth-of-type(2) {
  border-top: 0
}

.gridItem:nth-of-type(2n-1) {
  border-right: 2px solid var(--crt-bg-100)
}

.gridItem:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.gridItem > img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000044;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  transition: .3s;
  opacity: 0;
}

.info:hover {
  opacity: 1;
}

.marker:hover + .info {
  opacity: 1;
}
.marker:hover {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}


.title {
  font-size: 16px;
  font-weight: 700;
  cursor: var(--cursor-pointer);
}

.location {
  font-size: 14px;
  cursor: var(--cursor-pointer);
}
.marker {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  right: 10px;
  bottom: 10px;
  z-index: 3;
  transition: .3s;
  cursor: var(--cursor-pointer);
}