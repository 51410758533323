.letterDetail{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.letter {
  margin: 50px;
  border: 2px solid var(--crt-fg-100);
  padding: 30px 20px;
  max-height: 40%;
  overflow: scroll;
}
.senderInfo {
  margin-bottom: 25px;
}
.name {
  font-size: 16px;
  font-weight: 600;
}
.id {
  font-size: 14px;
  font-weight: 500;
}

.body {
  text-align: justify;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 500;
}

.controller {
  position: absolute;
  bottom: 15px;
  right: 20px;
  display: flex;
  gap: 6px;
}
.controller.left {
  left: 20px;
}
.controller.right {
  right: 20px;
}