
.input {
    display: block;
    width: calc(100% - 100px);
    height: 60px;
    border: 2px solid var(--crt-fg-100);
    color: var(--crt-fg-100);
    background-color: var(--crt-bg-100);
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
    padding: 0 80px 0 20px;
    margin-bottom: -2px;
}

.input.disabled {
    background-color: var(--crt-bg-200);
    color: var(--crt-fg-300);
}

@media (max-width: 1200px) {
    .input {
      width: 200px;
    }
  }
  