.containerWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-right: 2px solid var(--crt-fg-100);
  border-left: 2px solid var(--crt-fg-100);
  transform: translateX(100%);
  /* transition: 1s; */
  position: absolute;
  width: 100%;
  background-color: var(--crt-bg-100-hl);
  top: 0;
  left: -2px;
}

.containerWrapper.loaded {
  transform: translateX(0);
}

.container {
  width: 100%;
  height: calc(100% - 20px);
  overflow-x: hidden;
  color: var(--crt-fg-100);
}

.imageWrapper {
  overflow: scroll;
  height: 100%;
  display: flex;
  gap: 20px;
  margin: 20px;
}
.imageWrapper > img {
  width: 100%;
  height: 100%;
}
