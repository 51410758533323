.titleContainer {
  display: flex;
  position: relative;
  height: 60px;
  border-bottom: 2px solid var(--crt-grey);
}

.title {
  width: calc(100% - 2px);
  font-size: 20px;
  font-weight: 600;
  border: 0;
  padding: 0 20px;
  height: 60px;
}

.title > div {
  display: flex;
  align-items: center;
  height: 100%;
}

.closeBtnContainer {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.contentContainer {
  height: calc(50vw - 166px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.controllerContainer {
  border-top: 2px solid var(--crt-grey);
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding: 0 20px;
  position: relative;
}
