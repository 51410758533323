.info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: var(--crt-bg-100);
}

/* .searchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  background-color: var(--crt-bg-100-hl);
} */

.dualInputContainer {
  display: flex;
  overflow-x: hidden;
  height: 64px;
}

.dualInputContainer > input {
  border-right: 0px;
  width: 99px;
}

.palette {
  display: flex;
  gap: 5px;
}

.countryResult {
  height: calc(100% - 118px);
  overflow-y: scroll;
  display: none;
}

.cityResult {
  height: calc(100% - 180px);
  overflow-y: scroll;
  display: none;
}

.cityResult.visible,
.countryResult.visible {
  display: block;
}

.inputContainer {
  position: relative;
}

.toggleButton {
  position: absolute;
  right: 15px;
  top: 50%;
  cursor: var(--cursor-pointer);
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}

.toggleButton.open > svg {
  rotate: 180deg;
}


.item {
  padding: 20px;
  background-color: var(--crt-bg-100);
  color: var(--crt-fg-100);
  display: flex;
  gap: 10px;
  cursor: var(--cursor-pointer);
  border-bottom: 1px solid var(--crt-grey);
  align-items: center;
}

.item.hidden {
  display: none;
}

.eng {
  font-size: 16px;
  font-weight: 600;
}

.kor {
  font-size: 14px;
  font-weight: 600;
  color: var(--crt-300);
}