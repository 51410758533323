.item {
    padding: 20px;
    background-color: var(--crt-bg-100);
    color: var(--crt-fg-100);
    display: flex;
    gap: 10px;
    cursor: var(--cursor-pointer);
    border-bottom: 1px solid var(--crt-bg-100-hl);
    align-items: center;
}
.item.hidden {
    display: none;
}
.eng {
    font-size: 16px;
    font-weight: 600;
}
.kor {
    font-size: 14px;
    font-weight: 600;
    color: var(--crt-light-gray);
}