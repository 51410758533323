.input {
    display: block;
    width: 380px;
    height: 60px;
    border-top: 0;
    border-left: 2px solid var(--crt-fg-100);
    border-bottom: 2px solid var(--crt-fg-100);
    border-right: 2px solid var(--crt-fg-100);
    background-color: var(--crt-bg-100);
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;

    color: var(--crt-fg-100);
}

.top {
    border-top: 2px solid var(--crt-fg-100);
}

.bottom {
    border-bottom: 0px solid var(--crt-fg-100);
}