.letter {
    padding: 20px;
    border-bottom: 2px solid var(--crt-fg-100);
}
.info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.sender {
    display: flex;
    align-items: center;
    gap: 3px;
}
.name {
    font-size: 16px;
    font-weight: 600;
    margin-right: 3px;
}
.id, .date {
    font-size: 12px;
    font-weight: 500;
    color: var(--crt-gray);
    display: flex;
    align-items: center;
}
.notification {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--crt-warning);
}
.text {
    display: -webkit-box;
    font-size: 14px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}