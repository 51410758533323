.card {
    width: calc(100% - 44px);
    min-width: 450px;
    padding: 10px;
    margin: 10px 10px 0px;
    border: 2px solid var(--crt-fg-100);
    border-radius: 10px;
    background-color: var(--crt-bg-100);
    color: var(--crt-fg-100);
    display: flex;
    gap: 10px;
}
.imageContainer {
    flex: 1;
    position: relative;
}
.imageContainer > img {
    width: 100%;
    border-radius: 10px;
}
.imageContainer > .date, .imageContainer > .photoCount {
    position: absolute;
    right: 10px;
    background-color: black;
    color: white;
    padding: 4px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
}

.imageContainer > .date {
    bottom: 10px;
}

.imageContainer > .photoCount {
    top: 10px;
}


.contents {
    flex: 1;
    margin: 20px 0;
}

.location {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    padding: 0 2px 2px 2px;
    padding-bottom: 3px;
    border-bottom: 2px solid var(--crt-fg-100);
}

.cityContainer {
    display: flex;
    align-items: center;
    gap: 2px;
    font-style: italic;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.placeName {
    font-size: 14px;
    font-weight: 700;
    margin-left: 3px;
}

.log {
    display: -webkit-box;
    text-align: justify;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}