.cardList {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}
.cardList > .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.cardList > .list:first-child {
    align-items:flex-end;
}