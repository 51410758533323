.input {
  width: calc(100% - 40px);
  height: 60px;
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
  color: var(--crt-fg-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: var(--cursor-default);
  margin-bottom: -2px;
}
.input.clickable {
  cursor: var(--cursor-pointer);
}

.top {
  border-top: 2px solid var(--crt-fg-100);
}

.bottom {
  border-bottom: 0px solid var(--crt-fg-100);
}
