
.interactiveBox {
  width: 400px;
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  overflow: hidden;
  position: relative;
}

.interactiveBox > div, .interactiveBox > input {
  margin: -2px -2px 0 -2px;
}
/* large */

@media (max-width: 1200px) {
  .interactiveBox {
    width: 200px;
  }
}
