.content {
  text-align: justify;
  position: absolute;
  line-height: 1.4;
  font-size: 14px;
  color: var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  overflow-y: scroll;
  padding-top: 80px;
  height: 0;
  padding: 0 20px;
  transition-property: height, margin-top;
  transition-delay: 0s, 0.3s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1), cubic-bezier(0.86, 0, 0.07, 1);
  transition-duration: 0.3s, 0s;
  border-bottom: 2px solid var(--crt-fg-100);
  font-weight: 500;
}
.content a {
  text-decoration: underline;
}
.content b {
  font-size: 16px;
}

.content ul {
  padding-inline-start: 30px;
  margin-top: 5px;
}

.content ul ul {
  padding-inline-start: 20px;
}
.content ul > li {
  margin-bottom: 5px;
}

.label {
  background-color: var(--crt-bg-100);
}

.content.tc {
  z-index: 10;
}

.content.pp {
  z-index: 15;
}

.content.unfold {
  padding: 0 20px;
  height: calc(100% - 58px);
  transition-delay: 0s, 0s;
  margin-top: 0px;
}

.content.tc.unfold {
  height: calc(100% - 116px);
}
