.button {
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    width: 140px;
    background-color: var(--crt-fg-100);
    color: var(--crt-bg-100);
    border-radius: 25px;
    transition: all .2s;
    cursor: var(--cursor-pointer);
}

.button.disabled {
    background-color: var(--crt-fg-400);
    cursor: var(--cursor-default);
}