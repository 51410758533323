.control {
    height: 30px;
    width: 30px;
    background-color: var(--crt-bg-100);
    border: 2px solid var(--crt-fg-100);
    transition: all .3s;
    cursor: var(--cursor-pointer);
    overflow-y: hidden;

}

.control > .imageContainer {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
}

.control.changing > .imageContainer {

    transform: translateY(-30);
}

.imageContainer > img, .imageContainer > svg{
    width: 18px;
}
.control.highlighted {
    background-color: var(--crt-fg-100);
}
.control:hover {
    background-color: var(--crt-bg-100-hl);
}
.control.highlighted:hover {
    background-color: var(--crt-fg-100);
}
.control.changing {
    width: 80px;
}
.changing > .text {
    transition-delay: 0.2s;
    transform: translateY(-30);
}
.text {
    height: 30px;
    width: 80px;
    background-color: var(--crt-fg-100);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    transition-delay: 0s;
    color: var(--crt-bg-100);
    font-weight: 600;
    font-size: 20px;
}