.navigation {
  width: 60px;
  height: 44px;
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  transition: background-color 0.3s;
  cursor: var(--cursor-pointer);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--crt-fg-100);
  font-weight: 800;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.navigation:hover {
  background-color: var(--crt-bg-200);
}

.navigation > img,
.navigation > svg {
  height: 25px;
  transform: translateY(3px);
}

.navigation.activated {
  background-color: var(--crt-fg-100);
  color: var(--crt-bg-100);
}

.navigation.activated > div > svg {
  background-color: var(--crt-fg-400);
}

@media (max-width: 1100px) {
  .navigation {
      width: 20%;
      bottom: 0;
      z-index: 100;
      border: 0;
      border-radius: 0;
  }
}
