@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

:root {
  --crt-bg-100: #fbfbfb;
  --crt-bg-200: #eeeeee;
  --crt-fg-100: #101820;
  --crt-fg-200: #333333;
  --crt-fg-300: #666666;
  --crt-fg-400: #bbbbbb;
  --crt-grey: #dddddd;
  --crt-blue: #0d419d;
  --crt-green: #0f5322;
  --crt-red: #8e1619;

  --cursor-default: url(assets/cursor/cursor-default.png), auto;
  --cursor-text: url(assets/cursor/cursor-text.png) 4.5 9, auto;
  --cursor-pointer: url(assets/cursor/cursor-pointer.png) 7.5 0, auto;
  --cursor-prev: url(assets/cursor/cursor-prev.png) 11 11, auto;
  --cursor-next: url(assets/cursor/cursor-next.png) 11 11, auto;
  --cursor-add: url(assets/cursor/cursor-add.png) 11 11, auto;
  --cursor-move: url(assets/cursor/cursor-move.png) 12.5 12.5, auto;
  --cursor-draggable: url(assets/cursor/cursor-draggable.png) 12.5 12.5, auto;
  --cursor-dragging: url(assets/cursor/cursor-dragging.png) 12.5 12.5, auto;
  --cursor-prev-d: url(assets/cursor/cursor-prev-disabled.png) 11 11, auto;
  --cursor-next-d: url(assets/cursor/cursor-next-disabled.png) 11 11, auto;

  --desktop-width: calc(100vw - 80px);
  --desktop-height: calc(90vh - 100px);

  --mobile-width: calc(100vw - 30px);
  --mobile-height: calc(100vh - 220px);

  --large-width: calc(100vw - 180px);
  --large-height: calc(90vh - 180px);
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--crt-fg-100);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--crt-fg-100);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--crt-fg-100);
}
a {
  color: var(--crt-fg-100);
  text-decoration: none;
  cursor: var(--cursor-pointer);
}

body {
  margin: 0;
  font-family: "Figtree", "Pretendard", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--crt-bg-100);
  color: var(--crt-fg-100);
  cursor: var(--cursor-default);
  overflow: hidden
}

input,
textarea {
  font-family: "Figtree", "Pretendard", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  color: var(--crt-fg-100);
  cursor: var(--cursor-text);
}
input:focus,
textarea:focus {
  outline: none;
}

button {
  font-family: "Figtree", "Pretendard", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  border: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border: 2px solid var(--crt-fg-100);
  position: relative;
  cursor: var(--cursor-pointer);
  pointer-events: none;
}
input[type="checkbox"]:checked + label::after {
  /* background: url("./assets/checked.svg"); */
  content: "";
  width: 20px;
  height: 18px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

input.last {
  border-bottom: 0;
}

textarea {
  border: none;
  background-color: var(--crt-bg-100);
  resize: none;
}

div::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.2s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.2s ease-in;
}

.page-divided,
.page-full {
  display: flex;
  background-color: var(--crt-bg-100);
}

.page-divided > .left {
  width: calc(40vw - 32px);
  border-right: 2px solid var(--crt-fg-100);
}

.page-divided > .right {
  width: calc(60vw - 50px);
}

.page-full {
  width: calc(100vw - 80px);
  background-color: var(--crt-bg-100);
}

.page-full.center {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.warning {
  margin: 20px 0 30px;
  height: 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(--crt-red);
  text-align: center;
}

[contenteditable] {
  outline: 0px solid transparent;
}

/* mobile */
@media (max-width: 1100px) {
  .page-divided > .left {
    width: var(--mobile-width);
    border-right: 0;
  }

  .page-divided > .right {
  }
}

/* large */

@media (min-width: 2000px) {
  .page-divided > .left {
    width: calc(40vw - 72px);
    border-right: 2px solid var(--crt-fg-100);
  }

  .page-divided > .right {
    width: calc(60vw - 108px);
  }
}
