.search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: var(--crt-bg-100-hl);
  gap: 70px;
}

.inputContainer {
  position: relative;
}

.searchButton {
  position: absolute;
  right: 15px;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  cursor: var(--cursor-pointer);
}

.searchResult {
  height: calc(100% - 64px);
  width: 100%;
  overflow: scroll;
}

/* SearchResult */

.item {
  padding: 15px 20px;
  background-color: var(--crt-bg-100);
  color: var(--crt-fg-100);
  display: flex;
  gap: 10px;
  cursor: var(--cursor-pointer);
  border-bottom: 1px solid var(--crt-grey);
}
.alphabet {
  font-size: 16px;
  font-weight: 600;
}

.placeName {
  font-size: 14px;
  font-weight: 600;
}

.address {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}
