
.colorDot {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    cursor: var(--cursor-pointer);
  }

  .colorDot.visible {
    visibility: visible;
    opacity: 1;
  }

  .colorDot.centered {
    pointer-events: none;
  }
  
  .colorDot.activated > div {
    opacity: 1
  }
  
  .colorDot > img {
    width: 26px;
    height: 26px;
    position: absolute;

  }
  .colorDot > div {
    width: 16px;
    height: 16px;
    border: 2px solid var(--crt-bg-100);
    border-radius: 11px;
    opacity: 0;
    position: absolute;
    z-index: 10;
  }