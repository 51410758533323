.temp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: var(--crt-bg-100);
  margin-bottom: -50px;
  gap: 70px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.new {
  border-bottom: 1px solid var(--crt-fg-100);
  font-size: 16px;
  font-weight: 500;
}