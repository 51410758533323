.layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}

.header {
  width: var(--desktop-width);
  height: 60px;
  display: flex;
  align-items: flex-end;
  margin-bottom: -2px;
  justify-content: space-between;
}

.logo {
  height: 20px;
  margin-bottom: 20px;
}

.container {
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  height: var(--desktop-height);
  width: var(--desktop-width);
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.transition {
  position: relative;
}

.transition > div {
  position: absolute;
  height: var(--desktop-height);
  overflow-y: hidden;
}

@media (max-width: 1100px) {
  .header {
    width: var(--mobile-width);
  }

  .layout {
    margin-top: 0;
    justify-content: unset;
  }
  .logo {
    margin-bottom: 10px;
  }
  .container {
    width: var(--mobile-width);
    height: var(--mobile-height);
    max-height: unset;
  }
  .container > .left {
    display: none;
  }
  .container > .right {
    width: 100%;
  }

  .transition > div {
    height: var(--mobile-height);
  }
}

/* large */

@media (min-width: 2000px) {
  .header {
    width: var(--large-width);
  }

  .container {
    width: var(--large-width);
    height: var(--large-height);
  }

  .transition > div {
    height: var(--large-height);
  }
}
