
.modalContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #55555599;
    backdrop-filter: blur(2px);
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
  }
  
  .modal {
    width: 360px;
    background-color: var(--crt-bg-100);
    border: 2px solid var(--crt-fg-100);
    color: var(--crt-fg-100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    position: relative;
  }
  
  .modalContainer.pop {
    visibility: visible;
    opacity: 1;
  }
  
  .exitModal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    cursor: var(--cursor-pointer);
  }


  .text {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 1.3;
    margin-top: 50px;
  }

  .buttonContainer {
    width: 100%;
    margin-top: 40px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    border-top: 2px solid var(--crt-fg-100);
    background-color: var(--crt-bg-200);
    display: flex;
    cursor: var(--cursor-pointer);
  }

  .button {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button:first-child {
    border-right: 2px solid var(--crt-fg-200);
  }

  .button.positive {
    color: var(--crt-blue);
  }
  
.loginModal {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
}
.reportModal {
  width: 270px;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  color: var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  border: 2px solid var(--crt-fg-100);
}