.accordionContainer {
  height: calc(100% - 165px);
  overflow: scroll;
  margin: 20px;
  border-top: 2px solid var(--crt-fg-100);
}
.accordion {
  overflow: scroll;
  cursor: var(--cursor-pointer);
  margin-bottom: 22px;
}
.menu {
  height: 60px;
  border: 2px solid var(--crt-fg-100);
  overflow: hidden;
  transition: all .3s;
  margin-top: -2px;
}

.menuTitle {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: all .3s;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
}




.menuTitle.activated {
  background-color: var(--crt-bg-200);
}

.menuTitle > svg {
  width: 20px;
  height: 20px;
}

.menuTitle > svg.horizontal {
  rotate: 270deg;
}

.menuTitle.activated > svg.vertical {
  rotate: 180deg;
}

.subMenuContainer {
  background-color: var(--crt-bg-200);
}

.subMenu {
  height: 60px;
  border-top: 2px solid var(--crt-grey);
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  transition: all .3s;
}

.subMenu.activated {
  background-color: var(--crt-grey);
}

.icon {
  width: 20px;
  height: 20px;
}
