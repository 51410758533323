.tabContainer {
  width: 100%;
  height: 45px;
  display: flex;
  border-bottom: 2px solid var(--crt-fg-100);
}

.tabContainer > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: var(--crt-grey);
  cursor: var(--cursor-pointer);
  transition: color .1s linear;
}

.tabContainer > div.selected {
  color: var(--crt-fg-100);
}
