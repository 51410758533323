.navList {
  display: flex;
  gap: 10px;
  margin-right: 15px;
  justify-content: end;
}

.profileContainer {
  width: 30px;
  height: 30px;
}

.profileContainer > img,
.profileContainer > svg {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  margin-top: -2px;
}
.profileContainer > svg {
  background-color: var(--crt-grey);
  transform: translateY(3px);
}

.login {
  transform: translateY(5px);
}

@media (max-width: 1100px) {
  .navList {
    width: calc(100% - 30px);
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 15px;
    z-index: 100;
    margin-right: 0;
    justify-content: space-between;
  }
}
