.letterListContainer {
    display: flex;
    width: 100%;
    padding-bottom: 15px;
}
.letterList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.cardList > .list:first-child {
    align-items:flex-end;
}