.button {
  height: 30px;
  width: 30px;
  background-color: var(--crt-bg-100);
  border: 2px solid var(--crt-fg-100);
  transition: all 0.3s;
  cursor: var(--cursor-pointer);
  overflow-y: hidden;
}

.button > .content {
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.button.changing > .content {
  transform: translateY(-30);
}

.content > svg {
  width: 32px;
  height: 32px;
}

.button.highlighted {
  background-color: var(--crt-fg-100);
}
.button:hover {
  background-color: var(--crt-bg-100-hl);
}
.button.highlighted:hover {
  background-color: var(--crt-fg-100);
}
.button.wide {
  width: 54px;
}
.button.changing {
  width: 90px;
}
.button.changing > .text {
  transition-delay: 0.2s;
  transform: translateY(-30);
}
.text {
  height: 30px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  transition-delay: 0s;
  font-weight: 600;
  font-size: 18px;
}
