.infoContainer {
  width: 100%;
  position: relative;
  height: calc(100% - 64px);
  border-bottom: 2px solid var(--crt-fg-100);
  overflow: scroll;
}

.imageContainer {
  margin: 20px;
  border: 2px solid var(--crt-fg-100);
  position: relative;
}

.imageContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.authorContainer {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
}

.profileContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile {
  height: 60px;
  width: 60px;
  border-radius: 32px;
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-grey);
}

.userName {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.userID {
  font-size: 14px;
  font-weight: 400;
}

.locationContainer {
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  font-weight: 600;
  height: 60px;
  margin: 0 20px;
  align-items: center;
}

.country {
  font-style: italic;
}

.cityContainer {
  display: flex;
  align-items: center;
  font-style: italic;
  gap: 5px;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.title {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 18px;
}

.placeContainer {
  width: calc(100% - 40px);
  font-weight: 600;
  height: 40px;
  background-color: var(--crt-bg-200);
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}
.place {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 15px;
}

.place > svg {
  width: 20px;
  height: 20px;
}

.date {
  color: var(--crt-fg-300);
  font-size: 12px;
}

.article {
  line-height: 1.6;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
}

.view {
  font-weight: 600;
  margin: 30px 0;
}

.controller {
  position: absolute;
  bottom: 15px;
  right: 20px;
  display: flex;
  gap: 6px;
}
.controller.left {
  left: 20px;
}
.controller.right {
  right: 20px;
} 

.controllerMenuContainer {
  overflow: hidden;
}

.toggleButton.opened {
  rotate: 180deg;
}

.controllerMenuContainer > .controllerMenu {
  display: flex;
  display: flex;
  gap: 6px;
  transition: 0.5s;
  transform: translateX(-80px);
}

.controllerMenuContainer.opened > .controllerMenu {
  transform: translateX(0);
}

.socialButton {
  height: 30px;
  display: flex;
  border: 2px solid var(--crt-fg-100);
  padding: 0 8px;
  align-items: center;
  font-weight: 600;
  cursor: var(--cursor-pointer);
  font-size: 18px;
}

.socialButton > svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  transition: fill 0.3s;
}

.likeButton {
  border-color: var(--crt-blue);
  color: var(--crt-blue);
}

