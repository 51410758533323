
.slideContainer {
  display: flex;
  overflow: scroll;
  position: absolute;
  height: 100%;
}
.slide {
  display: flex;
  transition: .3s;
}
.slide > img {
  flex: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideController {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.slideController > div {
  width: 50%;
  height: 100%;
}

.indicatorContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
  align-items: center;
}

.indicator {
  background-color: var(--crt-grey);
  width: 8px;
  height: 8px;
  border-radius: 10px;
  opacity: 0.5;
  margin: 2px;
  cursor: var(--cursor-pointer);
}

.indicator.isActivated {
  width: 8px;
  height: 8px;
  opacity: 1;
}