.mapContainer {
  position: absolute;
  height: 100%;
  right: 0;
  width: calc(60% - 2px);
  cursor: var(--cursor-move) !important;
}

.map {
  width: 100%;
  height: 100%;
  cursor: var(--cursor-move) !important;
}

.centerWriteMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lock {
  background-color: #10182066;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  backdrop-filter: grayscale(1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock.visible {
  opacity: 1;
  pointer-events: all;
}

.lock > svg {
  width: 26px;
  height: 26px;
}

.lock > div::after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: white;
}

.centerColorDot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.searchMarkerContainer {
  position: absolute;
  opacity: 1;
  transition: all 0.3s;
}

.searchMarker {
  cursor: var(--cursor-pointer);
  width: 35px;
  height: 35px;
  border-radius: 22px;
  background-color: var(--crt-bg-100);
  opacity: 0.9;
  border: 2px solid var(--crt-fg-100);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  transform: translate(-50%, -50%);
}

.writeMarker {
  cursor: var(--cursor-pointer);
  transform: translate(-50%, -100%);
  position: absolute;
  opacity: 1;
  transition: all 0.3s;
  visibility: hidden;
  width: 30px;
  height: 40px;
}

.writeMarker > svg {
  width: 100%;
  height: 100%;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.fixed {
  pointer-events: none;
}

.colorDot {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  cursor: var(--cursor-pointer);
}

.colorDot.centered {
  pointer-events: none;
}

.colorDot.activated > div {
  opacity: 1;
}

.colorDot > img {
  width: 26px;
  height: 26px;
  position: absolute;
}
.colorDot > div {
  width: 16px;
  height: 16px;
  border: 2px solid var(--crt-bg-100);
  border-radius: 11px;
  opacity: 0;
  position: absolute;
  z-index: 10;
}
/* mobile */
@media (max-width: 1100px) {
  .mapContainer {
    display: none;
  }
}
