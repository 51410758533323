.newLetter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.inputContainer {
  margin: 50px;
  width: calc(100% - 100px);
  border: 2px solid var(--crt-fg-100);
  max-height: 40%;
  overflow: scroll;
}
.receiver {
  padding: 20px;
  border-bottom: 2px solid var(--crt-fg-100);
  font-size: 16px;
  font-weight: 700;
}
.mailBody {
  padding: 20px;
  font-size: 15px;
  font-weight: 500;
  width: calc(100% - 40px);
  height: 300px;
  text-align: justify;
}

.buttonContainer {
  display: flex;
  gap: 20px;
}