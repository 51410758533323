
.toggle {
  height: 60px;
  display: flex;
  border-bottom: 2px solid var(--crt-bg-100-hl);
}
.toggle > div {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  cursor: var(--cursor-pointer);
}

.toggle > div.selected {
  border-bottom: 2px solid var(--crt-fg-100);
}