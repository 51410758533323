.leftContainer {
  height: 100%;
  position: relative;
  background-color: var(--crt-bg-100-hl);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  height: 100%;
  width: 100%;
  border-bottom: 2px solid var(--crt-fg-100);
  display: flex;
  overflow: scroll;
}

.imageBoxContainer {
  flex: 1;
  width: 100%;
  height: 100%;
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.cropperContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.controller {
  display: flex;
  justify-content: space-between;
  margin: 0 10% 24px;
  font-weight: 700;
}
.controller > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: var(--cursor-pointer);
}
.controller > div > svg {
  width: 20px;
  height: 20px;
}

.disactivated {
  color: var(--crt-grey);
}

.cropper {
  height: 70%;
  width: 80%;
  margin-left: 10%;
  position: relative;
  border: 2px solid var(--crt-fg-100);
}

.imageGridItem {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}


.imageBox {
  border: 2px solid var(--crt-fg-100);
  position: relative;
  cursor: var(--cursor-pointer);
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  margin: auto;
}

.imageBox:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.imageBox > div {
  width: 100%;
  height: 100%;
  position: relative;
}

.imageBox > div > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.cancel {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--crt-bg-100);
    border-radius: 15px;
    border: 2px solid var(--crt-fg-100);
    display: flex;
    align-items: center;
    justify-content: center;
}
.cancel > img {
    width: 13px;
    height: 13px;
}

.empty {
    position: absolute;
    width: 100%;
    height: 100%;
}

.empty > label {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 800;
    cursor: var(--cursor-pointer);
}
.empty > input{
    display: none;
}

.cancel > img {
    width: 30px;
}

.top {
  border-top: 2px solid var(--crt-fg-100);
}

.bottom {
  border-bottom: 0px solid var(--crt-fg-100);
}
