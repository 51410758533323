.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join,
.find {
  margin-top: 15px;
  border-bottom: 1px solid var(--crt-fg-100);
  font-size: 16px;
  font-weight: 500;
}

.join {
  margin-top: 30px;
}
