
.indicator {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 20px;
    background-color: var(--crt-red);
    border-radius: 6px;
    opacity: 0;
    transition: all .5s;
    top: 50%;
    transform: translateY(-50%);
}

.indicator.visible {
    opacity: 1;
}

.indicator.valid {
    background-color: var(--crt-green);
}

.idContainer {
    position: relative;
}

