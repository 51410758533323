.listView {
  padding: 20px;
  height: 100%;
  width: calc(100% - 40px);
  overflow: scroll;
}

.listItem {
  padding-bottom: 20px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--crt-grey);
  display: flex;
  gap: 20px;
  cursor: var(--cursor-pointer);
}

.imageContainer {
  width: 160px;
  height: 160px;
  position: relative;
}

.imageContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--crt-grey);
}
.imageContainer > div {
  position: absolute;
  background-color: #00000099;
  color: white;
  padding: 2px 5px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  max-width: 130px;
  overflow-x: hidden;
  white-space: nowrap;
  transition: all 0.3s;
}

.imageCount {
  top: 10px;
  right: 10px;
}

.locationContainer {
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.marker {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  transition: all 0.3s;
  margin-right: 0px;
}

.location {
  max-width: 110px;
}

.locationContainer:hover {
  white-space: unset;
}
.locationContainer:hover > .marker {
  width: 0;
  height: 0;
  margin-right: -5px;
}
.locationContainer:hover > .location {
  text-overflow: unset;
  overflow: unset;
  word-break: break-word;
}

.location {
  overflow: hidden;
  text-overflow: ellipsis;
}
.textContainer {
  width: calc(100% - 182px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.userName {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.article {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 14px;
  text-align: justify;
}
