.archive {
}

.profileContainer {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--crt-fg-100);
}
.profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.imageContainer {
  width: 100px;
  height: 100px;
  border-radius: 52px;
  overflow: hidden;
  position: relative;
  border: 2px solid var(--crt-fg-100);
}
.imageContainer > .edit {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--crt-fg-100);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  cursor: var(--cursor-pointer);
}

.imageContainer:hover > .edit {
  opacity: 1;
  visibility: visible;
}

.userName {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 3px;
  display: flex;
  gap: 3px;
  align-items: center;
}

.userName > .edit {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--crt-fg-100);
  border-radius: 5px;
  height: 19px;
  padding: 0px 8px;
  color: var(--crt-bg-100);
  transition: 0.3s;
  opacity: 0;
  cursor: var(--cursor-pointer);
}

.userName:hover > .edit {
  opacity: 1;
}

.userid {
  font-size: 14px;
  font-weight: 500;
}

.menu {
  position: relative;
  width: 75px;
  height: 35px;
  display: flex;
  cursor: var(--cursor-pointer);
  gap: 5px;
}

.peopleIndicator {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--crt-red);
  border-radius: 5px;
  left: 25px;
  top: 1px;
}
