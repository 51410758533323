.joinWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--crt-bg-100-hl);
}

.join {
  text-align: center;
}

.interactiveBoxContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.interactiveBox {
  width: 400px;
  border: 2px solid var(--crt-fg-100);
  background-color: var(--crt-bg-100);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  border-bottom: 0px;
}

.interactiveBox.full {
  overflow-y: hidden;
}

.interactiveBox > div,
.interactiveBox > input {
  margin-left: -2px;
}

.interactiveBox > div:first-child {
  margin-top: -2px;
}
.content {
  text-align: justify;
  line-height: 1.2;
  font-size: 14px;
  color: var(--crt-fg-100);
  overflow: hidden;
  padding-top: 80px;
  height: 0;
  padding: 0 20px;
}
.content.unfold {
  padding: 20px;
  padding-top: 80px;
  height: max-content;
}
.warning {
  margin: 15px;
  font-size: 14px;
  height: 15px;
  font-weight: 500;
  color: var(--crt-warning);
}
.btnWrapper {
  transition: all 1s;
}
.fix {
  position: fixed;
}
.fix:first-child {
  background-color: black;
}
.profile > .imageContainer {
  height: 248px;
  border-bottom: 2px solid var(--crt-fg-100);
  display: flex;
  justify-content: center;
  align-items: center;
}
.none {
  display: none;
}
.image {
  position: relative;
}
.image > .button {
  height: 192px;
  width: 192px;
  border-radius: 96px;
  border: 2px solid var(--crt-fg-100);
  cursor: var(--cursor-pointer);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image > input[type="file"] {
  display: none;
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  border: 2px solid var(--crt-fg-100);
  display: flex;
  border-radius: 10px;
  cursor: var(--cursor-pointer);
  opacity: 0;
  transition: 0.3s;
}

.visible {
  opacity: 1;
}

.delete > img {
  height: 10px;
  width: 10px;
}

.cropperWrapper {
  width: 800px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.cropper {
  position: relative;
  width: 800px;
  height: 400px;
  background: #333;
}
.croppedImage {
  width: 192px;
  height: 192px;
  border-radius: 96px;
  border: 2px solid var(--crt-fg-100);
}
.indicator {
  width: 12px;
  height: 12px;
  color: black;
  position: absolute;
  right: 15px;
  transform: translateY(-40px);
  background-color: var(--crt-warning);
  border-radius: 6px;
  opacity: 0;
  transition: all 0.5s;
}

.indicator.visible {
  opacity: 1;
}

.indicator.valid {
  background-color: var(--crt-granted);
}

.cancel {
  margin-top: 20px;
  border-bottom: 1px solid var(--crt-fg-100);
  font-weight: 600;
}
