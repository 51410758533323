.leftContainer {
  height: 100%;
  position: relative;
  background-color: var(--crt-bg-100-hl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.imageContainer {
  width: calc(100% - 40px);
  margin: 20px;
  background-color: var(--crt-bg-200);
  border: 2px solid var(--crt-fg-100);
  position: relative;
}

.imageContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: var(--cursor-pointer);
}
.empty > div {
  border-bottom: 1px solid var(--crt-fg-100);
  font-weight: 500;
}

.infoContainer {
  width: 100%;
  cursor: var(--cursor-pointer);
}

.info > div {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
}

.info > div > svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.folder {
  border-bottom: 1px solid var(--crt-grey);
}

.location {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 22px;
  font-weight: 600;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 5px;
  border-top: 2px solid var(--crt-fg-100);
  font-style: italic;
}

.cityContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.colorDot {
  width: 20px;
  height: 20px;
  background-color: #123456;
  border-radius: 10px;
}

.date {
  font-style: normal;
}

.title {
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  border: none;
  width: 100%;
  background-color: var(--crt-bg-100);
  padding: 0;
}

.article {
  flex: 1 0 auto;
  width: calc(100% - 40px);
  height: calc(100% - 44px);
  color: var(--crt-fg-100);
  font-size: 15px;
  text-align: justify;
  padding: 20px;
}

.save {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.temp {
  color: var(--crt-gray);
  opacity: 0;
  transition: 0.3s;
  font-size: 14px;
}

.temp.visible {
  opacity: 1;
}

.controls {
  display: flex;
  gap: 5px;
}