.icon > svg{
    height: 100px;
    width: 100px;
}

.message {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 80px 0;
}

.home {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid var(--crt-fg-100);
}